<template>
  <div class="page-list personnelfiles flex-1">
    <a-row class="filter-wrap  ">
      <a-button
        type="primary"
        class="table-btn-clk"
        style="margin-right:19px; z-index: 1;margin-left: 0px;height: 32px;"
        @click="addRules('',1)"
      >
        <i
          class="meiye-icon meiye-routeicon-add-copy"
          style="color:#fff;font-size:12px;margin-right:4px;"
        ></i>
        添加品牌
      </a-button>
    </a-row>
    <!-- 筛选项 写死 -->
    <table-filter
      :flitList="flitList"
      :isAllLength="table.pagination.total"
      :isShowAll="isShowAll"
      @transferson="clearfliterSingle"
    ></table-filter>
    <a-table
      class="table-manage flex-1"
      :class=" (flitList[0].value.length || flitList[1].value.length || flitList[2].value.length ||flitList[3].value.length)?'':'pdt-6'"
      @change="tableChanged"
      :pagination="table.pagination"
      row-key="brand_id"
      :loading="table.loading"
      :columns="config.brand.columns"
      :data-source="table.tableData"
      :scroll="{  y:getfullHeight(124)  }"
    >
      <template
        slot="_1"
        slot-scope="action, record"
      >
        <div
          class="table-manageone"
          v-if="!record.isaddoredit"
        >
          <a-tooltip>
            <template slot="title">
              {{  record.brand_name|| '--'}}
            </template>
            {{  record.brand_name || '--'}}
          </a-tooltip>
        </div>
        <div
          class="table-managetwo "
          v-else
        >
          <div class="managetwo_box">
            <a-input
              class="ant-input_1"
              v-model="record.brand_name"
              style="width:283px;margin-right:32px"
              placeholder="输入品牌名称，10个字以内"
              :maxLength="10"
            ></a-input>
            <a-radio-group
              class="radio_nomal1"
              v-model="record.brand_type"
            >
              <a-radio
                :value="1"
                :defaultChecked="true"
              >
                自有品牌
              </a-radio>
              <a-radio :value="2">
                合作品牌
              </a-radio>
            </a-radio-group>
            <a-button
              size="small"
              type="primary"
              class="mgr-12 small_primary"
              @click="handleBatch2('save',record)"
            >保存</a-button>
            <a-button
              size="small"
              class="mgr-12 smallbtn"
              @click="handleBatch2('cancle',record)"
            >取消</a-button>
          </div>

        </div>
      </template>
      <template
        slot="_6"
        slot-scope="action, record"
      >
        <span
          style="padding-left:8px;"
          v-if="!record.isaddoredit"
        > {{ record.brand_type==1?'自有品牌':'合作品牌' }}</span>

      </template>
      <span
        slot="goodsBrands"
        class="bigdropdown"
        :class="isShowBrands?'hovedropdown':''"
      >

        <a-dropdown
          :trigger="['click']"
          placement="bottomCenter"
          :overlayStyle='{"width":"80px"}'
          overlayClassName="mydropdown"
          v-model="isShowBrands"
        >
          <span style="width: 90px;display: inline-block;">
            <i class="meiye-icon meiye-leixing"></i>
            品牌类型
            <i
              class="meiye-icon meiye-xiala"
              style="width:10px; margin-top: -1px;"
            ></i>
            <span class="borderbox">

            </span>
          </span>
          <a-menu
            slot="overlay"
            :default-selected-keys="['99999']"
            :selected-keys="flitList[4].value"
          >
            <a-menu-item
              v-for="(v) in brandstypeList"
              @click=";getbrandsItem('brands',v)"
              :key="v.name"
              style="text-align:center;margin:0px 5px;padding: 5px 0px;border-radius: 3px;"
            >
              {{ v.name }}
            </a-menu-item>
          </a-menu>

        </a-dropdown>

      </span>
      <template
        slot="_3"
        slot-scope="action, record"
      >
        <span
          class="baseColor11"
          v-if="!record.isaddoredit"
        > {{ record.goods_brand }}</span>

      </template>
      <template
        slot="_7"
        slot-scope="action, record"
      >
        <a-button
          v-if="!record.isaddoredit"
          size="small"
          class="mgr-12 smallbtn"
          @click="addRules(record,3)"
        >编辑</a-button>
        <a-button
          v-if="!record.isaddoredit"
          size="small"
          class="mgr-12 smallbtn"
          @click="handleBatch('delete',[record],'提示')"
        >删除</a-button>
        <a-button
          style="letter-spacing: -1px;"
          v-if="!record.isaddoredit"
          size="small"
          class="mgr-12 smallbtn"
          @click="handleBatch2('detail',record)"
        >查看商品</a-button>
      </template>
    </a-table>
    <!-- 编辑添加 -->
    <a-modal
      :visible="permissionInfo.visible"
      width="750px"
      wrapClassName="commonModel"
      @cancel="permissionInfo.visible=false"
      :okText="permissionInfo.text"
      @ok="okclickmod"
      destroyOnClose
      :bodyStyle="{height: '258px'}"
    >
      <div class="constmodel">
        <a-row type="flex">

          <div class="linebox flex flex-1 align-item-center">
            <div class="lfbox"><span class="imgxing">*</span>品牌名称：</div>
            <div
              class="rgbox  "
              style="width: 258px;"
            >
              <a-input
                class="ant-input_1"
                v-model="permissionInfo.info.brand_name"
                placeholder="请输入品牌名称"
              ></a-input>
            </div>
          </div>

        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"><span class="imgxing">*</span>品牌状态：</div>
              <div class="rgbox">
                <a-radio-group v-model="permissionInfo.info.brand_status">
                  <a-radio :value="0">
                    不展示
                  </a-radio>
                  <a-radio :value="1">
                    展示
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </a-col>

        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"> 自定义排序</div>
              <div class="rgbox">
                <a-radio-group v-model="permissionInfo.info.brand_sort">
                  <a-radio :value="1">
                    正序
                  </a-radio>
                  <a-radio :value="2">
                    倒序
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row
          type="flex"
          style="justify-content: space-between;"
        >
          <a-col>
            <div class="linebox flex align-item-center">
              <div class="lfbox"> 品牌图标</div>
              <div class="rgbox">
                <icon-picker
                  v-model="permissionInfo.info.brand_icon"
                  :icon="permissionInfo.info.brand_icon"
                  :trigger="'hover'"
                  @getIconName="getIconName"
                >
                  <template #iconSelect>
                    <div class="icons">
                      <i
                        class="meiye-icon"
                        v-if="permissionInfo.info.brand_icon"
                        :class="permissionInfo.info.brand_icon"
                      ></i>
                      <a-icon
                        type="plus"
                        v-else
                      />
                    </div>
                  </template>
                </icon-picker>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span class="titmodel">{{ permissionInfo.title }}</span>
        </div>
      </template>
    </a-modal>
    <!-- 关联商品 服务 方案 储值卡 -->
    <a-modal
      :visible="association.visible"
      width="750px"
      wrapClassName="commonModel2 mrj_modal_normal"
      @cancel="association.visible=false"
      :footer="null"
      destroyOnClose
    >
      <div class="">
        <div
          class="consTabs"
          style="margin-bottom:24px;text-align: center;"
        >
          <a-radio-group
            v-model="association.flag"
            class="lable_radiogroup"
            style="width:320px;"
            @change="changeAssociation"
          >
            <a-radio-button
              value="1"
              style="width:80px;text-align: center;"
            >
              商品
            </a-radio-button>
            <a-radio-button
              value="2"
              style="width:80px;text-align: center;"
            >
              服务
            </a-radio-button>
            <a-radio-button
              value="3"
              style="width:80px;text-align: center;"
            >
              卡项
            </a-radio-button>
            <a-radio-button
              value="4"
              style="width:80px;text-align: center;"
            >
              储值卡
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="consTable">
          <a-table
            class="popTable3"
            :pagination="false"
            row-key="id"
            :loading="association.loading"
            :columns="association.config"
            :data-source="association.tableData"
          ></a-table>
          <div
            class="fenye small_pagination"
            id="quanfenye"
            style="height:24px;position:relative;"
          >
            <a-pagination
              v-if="association.pagination.total>0"
              size="small"
              :total="association.pagination.total"
              :defaultPageSize="8"
              v-model="association.pagination.current"
              @change="onShowSizeChange"
            />
            <span
              class="titNum"
              v-if="association.pagination.total"
            >共关联{{association.pagination.total}}件商品</span>
          </div>
        </div>
      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span
            class="titmodel"
            style="margin-right:8px;"
          >{{ association.title }}</span>

        </div>
      </template>
    </a-modal>
  </div>

</template>
<script> 
import storage from 'store'
import { GetmanageGoodslist, Getbrandlist, Createbrand, Updatebrand, Deletebrand, brandInfo, GetgoodsbrandsoptionsList } from '@/api/commodity'
import config from './config'
import axios from 'axios'
import IconPicker from '@/components/intimate/IconPicker'
export default {
  components: { IconPicker },
  data() {
    return {
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        token: storage.get('token'),
        "Content-Type": 'multipart/form-data'
      },
      coptionsList: [],
      query: {
        brand_name: undefined,
        brand_type: undefined,
        limit: 10,
        page: 1,
      },
      table: {
        tableData: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      flitList: [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' },
        { key: '品牌类型', value: [], paramas: 'brand_type' }
      ],
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          brand_id: undefined,
          brand_name: undefined,
          brand_icon: undefined,
          brand_status: undefined,// 
          brand_sort: 1,
          brand_type: undefined,//0合作 ,1自有
          isaddoredit: false,
        }
      },
      treeData: [],
      selectreeObj: {
        children: 'children',
        title: 'brand_name',
        key: 'brand_id',
        value: 'brand_id'
      },
      brandsList: [],
      brandstypeList: [
        { name: '全部', id: 99999 },
        { name: '合作品牌', id: 2 },
        { name: '自有品牌', id: 1 },
      ],
      activeStatus_catagory: [],
      activeStatus_status: [],
      activeStatus_brand: [],
      bandFlag: false,
      association: {
        visible: false,
        title: '品牌名称',
        obj: {
          name: 'www',
          num: '12',
        },
        loading: false,
        pagination: {
          current: 1,
          total: 1,
          pageSize: 8, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: false,
        },

        tableData: [
        ],
        config: [
          {
            title: '商品名称', //服务名称 方案名称 卡项名称
            dataIndex: 'goods_name',
            key: '_1',
            ellipsis: true,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '商品码',
            dataIndex: 'goods_bn',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },
        ],// 服务名称+商品码  卡项名称+商品码 方案名称+商品码 
        flag: '1',//商品名称1  服务名称2 方案名称3 卡项名称4
      },
      isShowAll: true,
      isShowBrands: false,
    }
  },
  async created() {
    // this.getCompanyoptionList()
    this.getList()
    this.getBrands()
  },
  methods: {
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    handleMenuClick({ key }) {
      this.handleBatch(key)
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        const { file_url } = info.file.response.data
        this.permissionInfo.info.permission_icon = file_url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    // 商品品牌
    getBrands() {
      GetgoodsbrandsoptionsList().then(res => {
        console.log(res, "getBrands");
        if (res.error_code == 0) {
          this.brandsList = res.data
          this.brandsList.unshift({
            brand_icon: "meiye-routeicon-chart-pie",
            brand_id: 99999,
            brand_name: "全部",
            brand_sort: 1,
            brand_status: 1,
            created_at: "2023-04-20 16:30:25",
          })
        }
      })
    },
    getbrandsItem(i, v) {
      // 
      if (i == 'brands') {
        this.activeStatus_brand = [v.name]
        this.flitList[4].value = [v.name]
        console.log(v, this.activeStatus_brand);
        if (v.name == '全部') {
          let arr = []
          this.brandstypeList.forEach(el => {
            if (el.name != '全部') {
              arr.push(el.name)
            }
          })
          this.activeStatus_brand = arr
          this.flitList[4].value = arr
          this.query.brand_type = undefined
        } else {
          this.query.brand_type = v.id
        }
      }
      this.query.page = 1
      this.isShowBrands = false
      this.getList()
    },
    // 删除 
    handleBatch(key, record, title = "删除") {
      let self = this
      if (self.bandFlag) {
        self.$message.error('您有编辑项未完成，请完成后操作！')
        return false
      }

      self.bandFlag = false
      let deleteRules = Deletebrand
      const urlArr = [
        { name: deleteRules, key: 'delete', okType: 'primary', msg: '仅删除品牌，解除与产品的关联，是否确认删除当前品牌？' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            brand_id: record[0].brand_id + '',
          };
          (activeObj.name)(data).then(res => {
            if (res.error_code == 0) {
              self.$message.success(res.data.message)
              self.query.page = 1
              self.getList()
              self.bandFlag = false
            }
          }).finally(r => {
            self.bandFlag = false
          })
        },
        onCancel() {
          self.bandFlag = false
        },

      });
      self.bandFlag = false
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          brand_name: self.query.brand_name,
          brand_type: self.query.brand_type
        },
        page: self.query.page,
        limit: self.query.limit
      }

      return Getbrandlist(params).then(res => {
        console.log(res.data, "data");
        res.data.list.forEach(el => {
          el.isaddoredit = false
        })
        const { list = [], total = 0 } = res.data
        self.table.tableData = list
        self.table.pagination = Object.assign({}, { pageSize: this.query.limit, current: this.query.page }, {
          total: total
        })
        console.log(self.table.tableData, "///self.table.tableData");
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 商品树形列表
    // getCompanyoptionList(e) {
    //   GetgoodsoptionsList().then(res => {
    //     this.treeRecursion(res.data)
    //     this.treeData = res.data
    //     console.log(this.treeData, '>>>this.coptionsList');
    //     if (!e) {
    //       // this.query.company_parent = res.data[0].company_id
    //       // this.permissionInfo.info.company_parent = res.data[0].company_id
    //       this.getList()
    //     }

    //   })
    // },

    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { limit: Number(pageSize), page: Number(current) })
      this.getList()
    },
    // 提交按钮
    okclickmod(e) {
      let data = {
        ...this.permissionInfo.info
      }
      if (this.permissionInfo.type == 3) {
        delete data.created_at
        delete data.updated_at
        // delete data.brand_id
        Updatebrand(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
            this.bandFlag = false
          }
        })
      } else {
        Createbrand(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
            this.bandFlag = false
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {
      if (this.bandFlag) {
        this.$message.error('您有编辑项未完成，请完成后操作！')
        return false
      }
      this.bandFlag = true

      this.permissionInfo.isDisabled = false
      this.permissionInfo.info = {
        brand_id: undefined,
        brand_name: undefined,
        brand_icon: undefined,
        brand_status: undefined,// 
        brand_sort: 1,
        isaddoredit: false,
      }

      if (i == 3) { //编辑打开
        this.permissionInfo.info.brand_id = e.brand_id
        this.permissionInfo.type = 3
        console.log(this.permissionInfo.info);
        this.table.tableData.forEach(el => {
          if (el.brand_id == e.brand_id)
            el.isaddoredit = true
        })
      } else { //
        this.permissionInfo.title = '添加商品品牌'
        this.permissionInfo.type = 1
        delete this.permissionInfo.info.brand_id
        console.log(this.permissionInfo);
        this.table.tableData.unshift({
          brand_id: undefined,
          brand_name: undefined,
          brand_icon: undefined,
          brand_status: 1,// 
          brand_sort: 1,
          brand_type: 1,
          isaddoredit: true,
        })
      }
    },

    openDatails() {
      brandInfo({ brand_id: this.permissionInfo.info.brand_id }).then(res => {
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
      })
    },


    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.brand_id) == 'number') {
          el.brand_id = el.brand_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      console.log(props)
      // if(props.record.children.length > 0){
      if (props.expanded) {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">关闭详情</a-button></a>
      } else {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">查看详情</a-button></a>
      }
      // }else{
      //     return <span style={{marginRight:8 }}></span>
      // }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.company_logo = res.data.url
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    // 获取icon
    getIconName(e) {
      this.permissionInfo.info.brand_icon = e
    },
    handleBatch2(e, i) { //查看商品

      // 
      this.permissionInfo.info = {
        ...i
      }

      let self = this
      if (e == 'save') {
        let a = i.brand_name.trim()
        if (!i.brand_name || !a) {
          return this.$message.error('请填写品牌名')
        }
        // 发送请求 新增保存
        if (i.brand_id) {
          this.permissionInfo.type = 3
        } else {
          this.permissionInfo.type = 1
        }
        delete this.permissionInfo.info.isaddoredit
        this.permissionInfo.info.brand_name = i.brand_name
        this.query.page = 1
        this.okclickmod()
        this.bandFlag = false
      }
      if (e == 'cancle') {
        this.bandFlag = false
        if (i.brand_id) {
          this.table.tableData.forEach(el => {
            if (el.brand_id == i.brand_id)
              el.isaddoredit = false
          })
        } else {
          this.table.tableData.shift()
        }
      }
      if (e == 'detail') {
        // 发送请求 新增保存
        if (this.bandFlag) {
          this.$message.error('您有编辑项未完成，请完成后操作！')
          return false
        }
        this.bandFlag = true
        if (i.brand_id) {
          this.permissionInfo.type = 3
        } else {
          this.permissionInfo.type = 1
        }
        delete this.permissionInfo.info.isaddoredit
        this.permissionInfo.info.brand_name = i.brand_name
        // 打开商品查看
        this.association.flag = '1'
        this.openAssociation(i)
        this.bandFlag = false
      }
      self.bandFlag = false
    },
    // 打开关联的品牌
    openAssociation(record) {
      // 
      this.bandFlag = false
      this.association.visible = true
      this.association.obj = record
      this.association.title = record.brand_name
      this.association.obj.num = 23
      // this.association.flag = '1'
      // 请求数据 得到 
      // this.association.tableData
      // pagination: {
      //     current: 1,
      //     total: 16,
      //     pageSize: 10, //每页中显示10条数据
      //     showSizeChanger: true,
      //     showQuickJumper: false,
      //   },
      // `goods_type` tinyint NOT NULL COMMENT '商品类型：1客装实物,2店装实物，3合作客装实物，4合作店装实物，5服务，6合作服务，7储值卡，8方案\n',
      let data = {
        filter: {
          goods_brand: this.association.obj.brand_id,
        },
        page: this.association.pagination.current,
        limit: this.association.pagination.pageSize
      }
      if (this.association.flag == 1) {
        data.filter = { goods_type: [1, 2, 3, 4], goods_brand: this.association.obj.brand_id, }
      }
      if (this.association.flag == 2) {
        data.filter = { goods_type: [5, 6], goods_brand: this.association.obj.brand_id, }
      }
      if (this.association.flag == 3) {
        data.filter = { goods_type: 8, goods_brand: this.association.obj.brand_id, }
      }
      if (this.association.flag == 4) {
        data.filter = { goods_type: 7, goods_brand: this.association.obj.brand_id, }
      }
      GetmanageGoodslist(data).then(res => {
        this.association.tableData = res.data.list
        this.association.pagination.total = res.data.total

      })
    },
    changeAssociation(e) {
      console.log(e.target.value, "changeAssociation");
      if (e.target.value == 1) {
        this.association.config[0].title = '商品名称'
      }
      if (e.target.value == 2) {
        this.association.config[0].title = '服务名称'
      }
      if (e.target.value == 3) {
        this.association.config[0].title = '方案名称'
      }
      if (e.target.value == 4) {
        this.association.config[0].title = '卡项名称'
      }
      this.openAssociation(this.association.obj)
    },
    onShowSizeChange(current, pageSize) {
      this.association.pagination.pageSize = pageSize;
      this.association.pagination.current = current;
      // 请求   this.association.tableData
      // this.opengrouptype();
      this.openAssociation(this.association.obj)
    },
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        this.query[e.paramas] = undefined
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        // 分类筛选项
        if (e.key == "分类") {
          this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        }
        this.query.page = 1
        this.getList()
      } else {
        this.permissionInfo.info.permission_id_list = this.permission_id_list = this.permission_id_list2 = this.permission_id_list3 = []
        // 全部清除
        this.clearfliterAll()
      }

    },
    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList[4].value = []
      this.permissionInfo.info.permission_id_list = []

      this.flitList = [
        { key: '类别', value: [], paramas: 'goods_type' },
        { key: '分类', value: [], paramas: 'goods_category' },
        { key: '状态', value: [], paramas: 'is_show' },
        { key: '品牌', value: [], paramas: 'goods_brand' },
        { key: '品牌类型', value: [], paramas: 'brand_type' }

      ]
      this.isAllLength = ""
      this.query.brand_type = undefined
      this.query.page = 1
      // todu 调取更新list的接口
      this.getList()
    }, 
  },


}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 20px 12px 20px 20px;
  overflow: hidden;
  // background: #fff;
  // height: 100%;
  min-height: calc(100vh - 91px);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0;
    z-index: 1;
    margin-bottom: 7px;
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 18px;
  height: 18px;
  // background: @btn2mianbgDColor;
  // box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
  //   inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  // border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  display: inline-block;
  width: 105px;
  height: 26px;
  // background: #f2f5f5;
  border-radius: 4px;
  line-height: 26px;
  padding: 0 8px 0 8px;
  &:hover {
    background: #f2f5f5;
  }
  // .borderbox {
  //   position: absolute;
  //   top: 4px;
  //   left: 55px;
  .meiye-xiala {
    position: absolute;
    top: 2px;
    left: 88px;
    font-size: 8px;
    margin-top: -1px;
    transform: scale(0.83);
    display: inline-block;
    // color: @btn2mianfontDColor;
    color: @fontColor4;
  }
  // &:hover {
  //   // border: 1px solid @btn2mianboderHColor;
  //   .meiye-xiala {
  //     position: absolute;
  //     top: 1px;
  //     left: 64px;
  //     font-size: 8px;
  //     margin-top: -1px;
  //     transform: scale(0.83);
  //     display: inline-block;
  //     // color: @btn2mianfontHColor;
  //     color: #414041;
  //     transform: scale(0.83) rotate(180deg);
  //   }
  // }
  // }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  // padding-left: 24px;
}
/deep/.ant-modal-footer {
  padding-bottom: 32px;
}
.btn-padding {
  display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  // margin-top: -23px;
}
/deep/ .borderbox.ant-dropdown-open {
  border-color: @baseColor35 !important;
  color: @baseColor35 !important;
  i {
    color: @baseColor35 !important;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}
/deep/ .ant-table-body {
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.meiye-icon {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
      color: red;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 45px !important;
  height: 45px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
.icons {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  border: 1px dashed #cbcbcb;
  .icon {
    font-size: 18px;
  }
  cursor: pointer;
  &:hover {
    color: @primaryColor;
    border: 1px dashed @primaryColor;
  }
}
.table-managetwo {
  position: relative;
  width: 100%;
  height: 54px;
  .managetwo_box {
    position: absolute;
    top: 0;
    height: 54px;
    left: 0;
    width: 647px;
    display: flex;
    align-items: center;
  }
}
.fenye {
  text-align: center;
}
.titNum {
  position: absolute;
  left: 30px;
  top: 2px;
  display: inline;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: @fontColor5;
  bottom: 20px;
  left: 0px;
}
.meiye-leixing {
  color: @fontColor5;
}
/deep/.filter-wrap2 .filter_sx {
  color: @fontColor3 !important;
}
.filter {
  margin-top: -9px;
  padding-bottom: 6px;
  background: #fff;
}
.table-manage {
  margin-top: -8px;
}
.hovedropdown {
  background: #f2f5f5;
}
/deep/ .table-manage .ant-table-thead > tr > th {
  margin-bottom: 0;
  padding-bottom: 2px !important;
}
/deep/.filter_clear {
  color: @fontColor5;
  display: inline-block;
  height: 24px;
  width: 46px;
  line-height: 24px;
  position: relative;
  text-align: right;
}
/deep/.filter_clear .meiye-shanchu {
  font-size: 14px;
  // line-height: 20px;
  position: absolute;
  left: 0;
  top: 1px;
}
</style>